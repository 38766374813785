import { Actions, AccessibilityActions } from '../../types/products/webProjectTypes';

import {
	ProductWebProjectAccessibilityInformation,
	ProductWebProject,
} from '@assisto/typescript-axios/lib';

export type WebProjectStateType = {
	product: ExtendedProductWebProject | null;
	loadingGet: boolean;
	loadingGetAll: boolean;
	loadingSave: boolean;
	loadingDelete: boolean;
	loadingAccessGet: boolean;
	loadingAccessGetAll: boolean;
	loadingAccessSave: boolean;
	loadingAccessDelete: boolean;
};

interface ExtendedProductWebProject extends ProductWebProject {
	accessibility_information?: ProductWebProjectAccessibilityInformation[];
}

const initialState: WebProjectStateType = {
	product: null,
	loadingGet: false,
	loadingGetAll: false,
	loadingSave: false,
	loadingDelete: false,
	loadingAccessGet: false,
	loadingAccessGetAll: false,
	loadingAccessSave: false,
	loadingAccessDelete: false,
};

export default function reducer(
	state: WebProjectStateType = initialState,
	actions: {
		product?: ExtendedProductWebProject | ProductWebProject;
		accessibility_information?: ProductWebProjectAccessibilityInformation[];
		id?: number;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.WEB_PROJECT_GET_REQUEST:
			return {
				...state,
				product: null,
				loadingGet: true,
			};
		case Actions.WEB_PROJECT_GET_SUCCESS:
			return {
				...state,
				product: actions.product,
				loadingGet: false,
			};
		case Actions.WEB_PROJECT_GET_FAILURE:
			return {
				...state,
				loading: false,
			};
		case Actions.WEB_PROJECT_GET_ALL_REQUEST:
			return {
				...state,
				product: null,
				loadingGetAll: true,
			};
		case Actions.WEB_PROJECT_GET_ALL_SUCCESS:
			return {
				...state,
				product: actions.product,
				loadingGetAll: false,
			};
		case Actions.WEB_PROJECT_GET_ALL_FAILURE:
			return {
				...state,
				loadingGetAll: false,
			};
		case Actions.WEB_PROJECT_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true,
			};
		case Actions.WEB_PROJECT_SAVE_SUCCESS:
			return {
				...state,
				product: Object.assign(state.product!, actions.product),
				loadingSave: false,
			};
		case Actions.WEB_PROJECT_SAVE_FAILURE:
			return {
				...state,
				loadingSave: false,
			};
		//ACCESSIBILITY
		case AccessibilityActions.ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST:
			return {
				...state,
				loadingAccessGetAll: true,
			};
		case AccessibilityActions.ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE:
			return {
				...state,
				loadingAccessGetAll: false,
			};
		case AccessibilityActions.ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					accessibility_information: actions.accessibility_information,
				},
				loadingAccessGetAll: false,
			};

		case AccessibilityActions.ACCESSIBILITY_INFORMATION_SAVE_REQUEST:
			return {
				...state,
				loadingAccessSave: true,
			};
		case AccessibilityActions.ACCESSIBILITY_INFORMATION_SAVE_FAILURE:
			return {
				...state,
				loadingAccessSave: false,
			};
		case AccessibilityActions.ACCESSIBILITY_INFORMATION_SAVE_SUCCESS:
			return {
				...state,
				loadingAccessSave: false,
			};

		default:
			return state;
	}
}
