import * as React from 'react';
import styled from 'styled-components/macro';
import { Power } from 'react-feather';

import { Tooltip, Menu, MenuItem, IconButton as MuiIconButton } from '@material-ui/core';

import { deleteJWT } from '../persistentState';
import { useTranslation } from 'react-i18next';

const IconButton = styled(MuiIconButton)`
	svg {
		width: 22px;
		height: 22px;
	}
	&:focus {
		background: rgba(0, 0, 0, 0.1);
	}
`;

function UserDropdown() {
	const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
	const { t } = useTranslation();

	const toggleMenu = (event: React.SyntheticEvent) => {
		setAnchorMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorMenu(null);
	};

	const handleSignOut = async () => {
		deleteJWT();
		window.location.href = `${window.REACT_APP_BACKEND_BASE_URL}`;
	};

	return (
		<React.Fragment>
			<Tooltip title="Account">
				<IconButton
					aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
					aria-haspopup="true"
					onClick={toggleMenu}
					color="inherit"
					aria-label="account"
				>
					<Power />
				</IconButton>
			</Tooltip>
			<Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
				{/*<MenuItem onClick={closeMenu}>Profile</MenuItem>*/}
				<MenuItem onClick={handleSignOut}>{t('USER_DROPDOWN.LOG_OUT')}</MenuItem>
			</Menu>
		</React.Fragment>
	);
}

export default UserDropdown;
