import React from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { darken } from 'polished';
import { DashboardRouteChildType } from '../types/routes';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../vendor/perfect-scrollbar.css';

import { Chip, Drawer as MuiDrawer, List as MuiList, ListItem } from '@material-ui/core';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { dashboardRoutes as routes } from '../routes/index';
import { ReactComponent as Logo } from '../vendor/logo.svg';
import { CornerDownLeft } from 'react-feather';
import { useSelector } from 'react-redux';
import { AppStateType } from '../redux/reducers';
import { useTranslation } from 'react-i18next';

const PAGE_TRANSLATION_KEY = 'SIDEBAR';

const Drawer = styled(MuiDrawer)`
	border-right: 0;

	> div {
		border-right: 0;
	}
`;

const Scrollbar = styled(PerfectScrollbar)`
	background-color: ${(props) => props.theme.sidebar.background};
	border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
	background-color: ${(props) => props.theme.sidebar.background};
`;

// const Items = styled.div`
//   padding-top: ${(props) => props.theme.spacing(2.5)}px;
//   padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
// `;

const Brand = styled(ListItem)<{
	button?: boolean;
	component?: React.ReactNode;
	to?: string;
}>`
	font-size: ${(props) => props.theme.typography.h5.fontSize};
	font-weight: ${(props) => props.theme.typography.fontWeightMedium};
	color: ${(props) => props.theme.sidebar.header.color};
	background-color: ${(props) => props.theme.sidebar.header.background};
	font-family: ${(props) => props.theme.typography.fontFamily};
	min-height: 56px;
	padding-left: ${(props) => props.theme.spacing(6)}px;
	padding-right: ${(props) => props.theme.spacing(6)}px;
	justify-content: center;
	cursor: pointer;

	${(props) => props.theme.breakpoints.up('sm')} {
		min-height: 64px;
	}

	&:hover {
		background-color: ${(props) => darken(0.3, props.theme.sidebar.background)};
	}
	&:focus {
		background: rgba(0, 0, 0, 0.08);
		border: 2px solid ${(props) => props.theme.sidebar.color};
	}
`;

const BrandIcon = styled(Logo)`
	margin-right: ${(props) => props.theme.spacing(2)}px;
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
`;

type CategoryType = {
	activeClassName?: string;
	button?: boolean;
	onClick?: () => void;
	to?: string;
	component?: typeof NavLink;
	exact?: boolean;
};

const Category = styled(ListItem)<CategoryType>`
	padding-top: ${(props) => props.theme.spacing(3)}px;
	padding-bottom: ${(props) => props.theme.spacing(3)}px;
	padding-left: ${(props) => props.theme.spacing(8)}px;
	padding-right: ${(props) => props.theme.spacing(7)}px;
	font-weight: ${(props) => props.theme.typography.fontWeightRegular};

	svg {
		color: ${(props) => props.theme.sidebar.color};
		font-size: 45px;
		width: 45px;
		height: 45px;
		margin: 0 auto;
		opacity: 0.5;
	}

	&:hover {
		background-color: ${(props) => darken(0.3, props.theme.sidebar.background)};
	}
	&:focus {
		background: rgba(0, 0, 0, 0.08);
		border: 2px solid ${(props) => props.theme.sidebar.color};
	}

	&.${(props) => props.activeClassName} {
		background-color: ${(props) => darken(0.3, props.theme.sidebar.background)};

		span {
			color: ${(props) => props.theme.sidebar.color};
		}
	}
`;

const CategoryIconLess = styled(ExpandLess)`
	color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
	color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const LinkBadge = styled(Chip)`
	font-size: 11px;
	font-weight: ${(props) => props.theme.typography.fontWeightBold};
	height: 20px;
	position: absolute;
	right: 28px;
	top: 8px;
	box-sizing: border-box;
	background: ${(props) => props.theme.sidebar.badge.background};

	span.MuiChip-label,
	span.MuiChip-label:hover {
		cursor: pointer;
		color: ${(props) => props.theme.sidebar.badge.color};
		padding-left: ${(props) => props.theme.spacing(2)}px;
		padding-right: ${(props) => props.theme.spacing(2)}px;
	}
`;

const CategoryBadge = styled(LinkBadge)`
	top: 12px;
`;

type SidebarCategoryPropsType = {
	name: string;
	icon: JSX.Element;
	classes?: string;
	isOpen?: boolean;
	isCollapsable: boolean;
	badge?: string | number;
	activeClassName?: string;
	button: true;
	onClick?: () => void;
	to?: string;
	component?: typeof NavLink;
	exact?: boolean;
};

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
	name,
	icon,
	classes,
	isOpen,
	isCollapsable,
	badge,
	...rest
}) => {
	const { t } = useTranslation();
	return (
		<li>
			<Category
				role="link"
				aria-label={t(PAGE_TRANSLATION_KEY + '.' + name)}
				exact={false}
				{...rest}
			>
				{icon}
				{/*<CategoryText>{name}</CategoryText>*/}
				{isCollapsable ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
				{badge ? <CategoryBadge label={badge} /> : ''}
			</Category>
		</li>
	);
};

type SidebarPropsType = {
	classes?: string;
	staticContext: string;
	location: {
		pathname: string;
	};
	routes: Array<DashboardRouteChildType>;
	PaperProps: {
		style: {
			width: number;
		};
	};
	variant?: 'permanent' | 'persistent' | 'temporary';
	open?: boolean;
	onClose?: () => void;
};

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
	classes,
	staticContext,
	location,
	...rest
}) => {
	const auth = useSelector((state: AppStateType) => state.auth);
	return (
		<Drawer variant="permanent" {...rest}>
			<Scrollbar role="navigation">
				<List disablePadding>
					<li>
						<Brand aria-label="home" role="link" component={NavLink} to="/" button>
							<BrandIcon title="logo" />
							{/*<BrandIcon />{" "}
          <Box ml={1}>
            Material App <BrandChip label="PRO" />
          </Box>*/}
						</Brand>
					</li>
					{/* <Items> */}
					{routes.children
						? routes.children.map((category: DashboardRouteChildType, index) => {
								if (!category.canRender) {
									return (
										<React.Fragment key={index}>
											{category.icon ? (
												<SidebarCategory
													isCollapsable={false}
													name={category.name}
													to={category.path}
													activeClassName="active"
													component={NavLink}
													icon={category.icon}
													exact={false}
													button
													badge={category.badge}
												/>
											) : null}
										</React.Fragment>
									);
								}

								if (category.canRender(auth)) {
									return (
										<React.Fragment key={index}>
											{category.icon ? (
												<SidebarCategory
													isCollapsable={false}
													name={category.name}
													to={category.path}
													activeClassName="active"
													component={NavLink}
													icon={category.icon}
													exact={false}
													button
													badge={category.badge}
												/>
											) : null}
										</React.Fragment>
									);
								}

								if (auth.config?.user?.beranetRoles?.zone) {
									return (
										<React.Fragment key={index}>
											{category.icon ? (
												<SidebarCategory
													isCollapsable={false}
													name={category.name}
													to={category.path}
													activeClassName="active"
													component={NavLink}
													icon={category.icon}
													exact={false}
													button
													badge={category.badge}
												/>
											) : null}
										</React.Fragment>
									);
								}

								return null;
						  })
						: null}
					<React.Fragment key={255}>
						<SidebarCategory
							isCollapsable={false}
							name={'RETURN'}
							to={'/return'}
							activeClassName="active"
							component={NavLink}
							icon={<CornerDownLeft />}
							onClick={() => {
								window.location.href = `${window.REACT_APP_BACKEND_BACKJUMP}`;
							}}
							exact
							button
						/>
					</React.Fragment>
					{/* </Items> */}
				</List>
			</Scrollbar>
			{/*<SidebarFooter>
        <Grid container spacing={2}>
          <Grid item>
            <SidebarFooterBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                alt="Lucy Lavender"
                src="/static/img/avatars/avatar-1.jpg"
              />
            </SidebarFooterBadge>
          </Grid>
          <Grid item>
            <SidebarFooterText variant="body2">Lucy Lavender</SidebarFooterText>
            <SidebarFooterSubText variant="caption">
              UX Designer
            </SidebarFooterSubText>
          </Grid>
        </Grid>
      </SidebarFooter>*/}
		</Drawer>
	);
};

export default withRouter(Sidebar);
