export enum Actions {
	WEB_PROJECT_GET_REQUEST = 'WEB_PROJECT_GET_REQUEST',
	WEB_PROJECT_GET_SUCCESS = 'WEB_PROJECT_GET_SUCCESS',
	WEB_PROJECT_GET_FAILURE = 'WEB_PROJECT_GET_FAILURE',
	WEB_PROJECT_GET_ALL_REQUEST = 'WEB_PROJECT_GET_ALL_REQUEST',
	WEB_PROJECT_GET_ALL_SUCCESS = 'WEB_PROJECT_GET_ALL_SUCCESS',
	WEB_PROJECT_GET_ALL_FAILURE = 'WEB_PROJECT_GET_ALL_FAILURE',
	WEB_PROJECT_SAVE_REQUEST = 'WEB_PROJECT_SAVE_REQUEST',
	WEB_PROJECT_SAVE_SUCCESS = 'WEB_PROJECT_SAVE_SUCCESS',
	WEB_PROJECT_SAVE_FAILURE = 'WEB_PROJECT_SAVE_FAILURE',
}

export enum AccessibilityActions {
	ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST = 'ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST',
	ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS = 'ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS',
	ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE = 'ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE',
	ACCESSIBILITY_INFORMATION_SAVE_REQUEST = 'ACCESSIBILITY_INFORMATION_SAVE_REQUEST',
	ACCESSIBILITY_INFORMATION_SAVE_SUCCESS = 'ACCESSIBILITY_INFORMATION_SAVE_SUCCESS',
	ACCESSIBILITY_INFORMATION_SAVE_FAILURE = 'ACCESSIBILITY_INFORMATION_SAVE_FAILURE',
}
