import { combineReducers } from 'redux';
import webBasic, { WebBasicStateType } from './webBasicReducer';
import messengerApp, { MessengerAppStateType } from './messengerAppReducer';
import groups, { GroupsStateType } from './groupsReducer';
import webMessenger, { WebMessengerStateType } from './webMessengerReducer';
import companyProducts, { CompanyProductsStateType } from './companyReducer';
import products, { ProductStateType } from './productReducer';
import questo, { QuestoStateType } from './questoReducer';
import webProject, { WebProjectStateType } from './webProjectReducer';
export const productsReducer = combineReducers({
	webBasic,
	messengerApp,
	groups,
	questo,
	webMessenger,
	companyProducts,
	products,
	webProject,
});

export interface ProductsStateType {
	webBasic: WebBasicStateType;
	messengerApp: MessengerAppStateType;
	groups: GroupsStateType;
	companyProducts: CompanyProductsStateType;
	webMessenger: WebMessengerStateType;
	questo: QuestoStateType;
	products: ProductStateType;
	webProject: WebProjectStateType;
}
